import React from "react"
import Layout from "../components/layouts/Layout/layout"
import Banner from "../components/sections/Banner/Banner"
import NextFormations from "../components/sections/NextFormations/NextFormations"
import TextColumns from "../components/sections/TextColumns/TextColumns"
import Seo from "../components/seo"
import componentFactory from "../utils/componentFactory"

const TypeEvenementTemplate = ({ pageContext }) => {
  const item = pageContext.item.attributes

  const components = pageContext.page.attributes.contenu.map(i =>
    componentFactory(i, pageContext)
  )

  const chemin = [...pageContext.page.attributes.chemin]
  chemin.push({
    titre: item.titre
  })

  return (
    <>
      <Seo
        description={item.metaDescription}
        title= {'Formation : ' +item.titre}
        lang="fr"
      ></Seo>
      <Layout chemin={chemin}>
        <Banner
          title={item.titre}
          description={item.description}
          backgroundColor={item.couleur}
          image={item.baniere.data.attributes.url}
          alt={item.baniere.data.attributes.alternativeText}
        />
        <TextColumns
          title={item.formation}
          content={item.contenu}
        ></TextColumns>
        <TextColumns title="Durée" titleLevel="h3" content={item.duree}></TextColumns>
        <TextColumns title="Programme" titleLevel="h3" content={item.programme}></TextColumns>
        <NextFormations
          strapiUrl={pageContext.strapiUrl}
          typeEvenementId={pageContext.item.id}
          typeEvenementName={item.titre}
        />

        {components}
      </Layout>
    </>
  )
}

export default TypeEvenementTemplate
